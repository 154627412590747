* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
}

body {
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

table {
    padding-top: 20px;
    margin-bottom: 20px;
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
        minmax(200px, 1fr)
        minmax(450px, 1.67fr)
        minmax(200px, 1.67fr)
        minmax(200px, 1.67fr)
        minmax(200px, 3.33fr)
        minmax(200px, 1.67fr)
        minmax(200px, 3.33fr)
        minmax(200px, 1.67fr);
}

thead,
tbody,
tr {
    display: contents;
    text-align: left;
    page-break-before: avoid;
}

tr.page-break {
    display: block;
    page-break-before: always;
}

th,
td {
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-x: scroll;
    text-overflow: scroll;
    white-space: nowrap;
    text-align: left;
    width: 100%;
}

td::-webkit-scrollbar,
th::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

th {
    position: sticky;
    top: 0;
    overflow-y: hidden;
    background: tomato;
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    color: white;
    width: 100%;
}

th:last-child {
    border: 0;
}

td {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #808080;
}

tr:nth-child(even) td {
    background: #f8f6ff;
}

/*

*/

table {
    border-collapse: collapse;
    padding-left: 10px;
    overflow: scroll;
    width: 100%;
}

/* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */

.flex-container {
    /* We first create a flex layout context */
    display: flex;

    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;

    /* Then we define how is distributed the remaining space */
    justify-content: space-around;

    padding: 0;
    margin: 0;
    list-style: none;
}

.flex-item {
    background: tomato;
    padding: 5px;
    width: 200px;
    height: 150px;
    margin-top: 10px;
    line-height: 150px;
    color: white;
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    flex-grow: 1;
}

.flex-item {
    background: tomato;
    padding: 5px;
    width: 200px;
    height: 150px;
    margin-top: 10px;
    line-height: 150px;
    color: white;
    font-weight: bold;
    font-size: 1em;
    text-align: center;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    border-radius: 5px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

img {
    border-radius: 5px 5px 0 0;
}

.container {
    padding: 2px 16px;
}

.card {
    margin: 10px;
}
